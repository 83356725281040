import React from 'react';
export interface IBadgeProps {
    badgeNew?: boolean | false; 
    promoJollyImageUrl?: string | false; 
    promoJollyLabel?: string | false; 
    promoJollyText?: string | false; 
    promoJollyTextColor?: string | false; 
    promoJollyBg?: string | false; 
    promoLabel?: string | false; 
    promoText?: string | false; 
    percentualDiscount?: number | false;
    percentualDiscountLabel?: string | false; 
}
interface IStyle {
    style?: {
        color?: string;
        backgroundColor?: string;
    }
}
const ProductBadges = (props: IBadgeProps) => {
    const {badgeNew, promoJollyImageUrl, promoJollyLabel, promoJollyText, promoJollyTextColor, promoJollyBg, promoLabel, promoText, percentualDiscount, percentualDiscountLabel} = props;

    const jollyTextColor:IStyle = {};
    const jollyBg: IStyle = {};
    if(promoJollyTextColor && promoJollyTextColor !== ''){
        jollyTextColor.style = {
            color: promoJollyTextColor
        }
    }

    if(promoJollyBg && promoJollyBg !== ''){
        jollyBg.style = {
            backgroundColor: promoJollyBg
        }
    }
    
    return (
       <div className='msc-product-badge__ctn'>
            { promoJollyImageUrl ? (
                <div className='msc-product-badge badge--image'><img src={promoJollyImageUrl} /></div>
            ) : (
                promoJollyLabel && (promoJollyLabel !== '' || promoJollyText !== '') && (
                    <div className='msc-product-badge badge--jolly' {...jollyBg}>
                        <span className='label' {...jollyTextColor}>
                            {promoJollyLabel && promoJollyLabel !== '' && promoJollyLabel}
                        </span>
                        <span className='value'  {...jollyTextColor}>
                            {promoJollyText && promoJollyText}
                        </span>
                    </div>
                )
            )}
            {promoText && promoText !== '' && (
                <div className='msc-product-badge badge--promo'>
                    <span className='label'>
                        {promoLabel && promoLabel}
                    </span>
                    <span className='value'>
                        {promoText && promoText}
                    </span>
                </div>
            )}
            {percentualDiscount !== undefined && percentualDiscount !== false && (
                <div className='msc-product-badge badge--percentual'>
                    <span className='label'>
                        {percentualDiscountLabel && percentualDiscountLabel}
                    </span>
                    <span className='value'>
                        { percentualDiscount >= 0 ? 
                            <span>PROMO</span> 
                            : 
                            <>{percentualDiscount.toFixed(0)}%</>
                        }
                    </span>
                </div>
            )}
            {badgeNew && badgeNew === true && <div className='msc-product-badge badge--new'>NEW</div>}
        </div>
    )
}

export default ProductBadges;